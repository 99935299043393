import { DatePicker, Select, Space, Table, Tag } from "antd";
import { filter } from "core/assets/icons";
import {
  FetchAndUnmask,
  FormatDate,
  Loader,
  convertToFirstLetterCap,
  formatMoneyLocal,
} from "core/helpers";
import dayJs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import { Download } from "app/components/download";
import { getAmountFilterOptions } from "../../../../../services/utilityService";
import {
  useGetPaginatedAllMda,
  useGetMdaWithService,
} from "../../../../payments/hooks/useRevenuePayment";
import { useGetOagfInFlow } from "../../../Payments/hooks/usePayment";
import {
  fetchTableData,
  fetchTableDataReset,
} from "../../components/GetTableData";
import { TableComponent } from "app/components/table/Table";
import DropDown from "app/components/dropdown";
const { RangePicker } = DatePicker;

const CollectionInflowOAGF = () => {
  const [amountFilter, setAmountFilter] = useState<string>("");
  const [canLoad, setCanLoad] = useState<boolean>(false);
  const [amountFilterOptions, setAmountFilterOptions] = useState<any[]>([]);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const {
    data: InFlowData,
    mutate: mutateInFlowData,
    isLoading: isLoadingInFlowData,
  } = useGetOagfInFlow();

  const [allData, setAllData] = useState<any>([]);

  const {
    data: mdaNameList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetPaginatedAllMda();

  const dataSourceForExcel: any[] = [
    [
      "GOVERNMENT REVENUE REFERENCE",
      "SERVICE",
      "REVENUE CODE",
      "TRANSACTION AMOUNT",
      "PROCESSING FEE",
      "VAT PROCESSING FEE",
      "TOTAL PROCESSING FEE",
      "NET AMOUNT",
      "CURRENCY",
      "PURPOSE",
      "BANK NAME",
      "ACCOUNT NAME",
      "ACCOUNT CLASSIFICATION",
      "ACCOUNT NUMBER",
      "GL ACCOUNT",
      "PAYMENT CHANNEL",
      "PAYMENT GATEWAY NAME",
      "PAYER NAME",
      "EMAIL",
      "PHONE NO",
      // "PAYMENT REFERENCE",
      "PAYMENT STATUS",
      "ORG NAME",
      "DATE AND TIME",
      "CUMULATIVE NET AMOUNT",
    ],
  ];

  useEffect(() => {
    setAllData(mdaNameList);
  }, [mdaNameList]);

  // const MDAName = Array.from(new Set(allData?.map((obj: any) => obj))).map(
  //   (obj: any) => ({
  //     value: obj?.id,
  //     label: obj?.registeredName,
  //   })
  // );

  const lazydayUser = JSON.parse(FetchAndUnmask("lazyday_user") as string);

  const dataSource: any[] = [];
  let cumulativeNetAmount = 0;

  function getAmountFilterOption() {
    const amounts = InFlowData?.data?.map((obj) => obj.amount);
    if (amounts?.length > 0 && amountFilterOptions.length === 0) {
      setAmountFilterOptions(getAmountFilterOptions(amounts, 5));
    }
  }

  for (let i = 0; i <= InFlowData?.data?.length; i++) {
    const currentData = InFlowData?.data[i];

    if (currentData) {
      const vat = currentData.vat;
      const totalFee = currentData.processingFee + vat;
      const netAmount = totalFee + currentData.amount;
      cumulativeNetAmount += currentData.amount ;

      const datad = {
        key: i.toString(),
        sn: i + 1,
        "Government Revenue Reference (GRR)": currentData.installPayment
          ? currentData.paymentReference
          : currentData.invoiceNumber,
        service: currentData.service,
        revenueCode: currentData?.revCode || "N/A",
        transactionAmount: currentData.amount
          ? formatMoneyLocal(currentData.amount)
          : "0.00",
        processingFee: formatMoneyLocal(currentData.processingFee) || "0.00",
        vatFee: formatMoneyLocal(vat) || "0.00",
        totalFee: formatMoneyLocal(totalFee),
        netAmount: currentData.amount
          ? netAmount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",

        currency: "NGN",
        purpose: currentData.purpose || "N/A",
        bankName: currentData.receivingBank,
        accountName: currentData.receivingAccountName,
        accountClassification: currentData?.accountType?.description,
        accountNumber: currentData.receivingAccount,
        paymentChannel: currentData.paymentChannel || "N/A",
        paymentGatewayName: currentData.paymentGateway || "N/A",
        payerName: currentData.fullName.trim(),
        email: currentData.email,
        phoneNo: currentData.phone,
        // paymentReference: currentData.paymentReference,
        paymentStatus: currentData.status,
        orgName: currentData.organizationName,

        dateAndTime: currentData.dateTime
          ? moment(currentData.dateTime).format("MMMM Do YYYY, h:mm a")
          : "",

        cumulativeNetAmount: cumulativeNetAmount
          ? cumulativeNetAmount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
          glAccount: currentData.glAccountName|| "N/A",
      };

      dataSource.push(datad);
      dataSourceForExcel.push(Object.values(datad).slice(2));
    }
  }
  const [searchFilterValue, setSearchFilterValue] = useState<string>('')
  useEffect(() => {
    let searchFilter = "";
    const urlParams = new URLSearchParams(window.location.search);
    const startDate = urlParams.get("start-date");
    const endDate = urlParams.get("end-date");
    const mda = urlParams.get("mda");
    const dir = urlParams.get("dir");

    if (startDate) {
      setStartDate(startDate);
      searchFilter += `start-date=${startDate}&`;
    }

    if (endDate) {
      setEndDate(endDate);
      searchFilter += `end-date=${endDate}&`;
    }

    if (startDate && endDate) {
      setDateRange([dayJs(startDate), dayJs(endDate)]);
    }

    // if (mda) {
    //   setOrgId(mda);
    //   searchFilter += `org-id=${mda}&`;
    // }
    
    if (dir) {
      searchFilter += `sort=paymentDate,${dir}&`;
    }

    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }

    setSearchFilterValue(searchFilter)
    
    const onSuccess = () => {
      setCanLoad(true);
    };
    fetchTableData(
      mutateInFlowData,
      tableParams,
      setTableParams,
      searchFilter,
      onSuccess
    );
    //mutateInFlowData(`size=10000`);
  }, []);

  useEffect(() => {
    getAmountFilterOption();
  }, [InFlowData]);

  const columns = [
    {
      title: "",
      children: [
        {
          title: "S/N",
          dataIndex: "sn",
          key: "sn",
          width: "40",
          sorter: (a, b) => a.sn - b.sn,
        },
        {
          title: "Date & Time",
          dataIndex: "dateAndTime",
          key: "dateAndTime",
          width: "77",
        },
        {
          title: "MDA Name",
          dataIndex: "orgName",
          key: "orgName",
          width: "77",
        },
        {
          title: "Government Revenue Reference (GRR)",
          dataIndex: "Government Revenue Reference (GRR)",
          key: "Government Revenue Reference (GRR)",
          width: "100",
        },
        {
          title: "Service",
          dataIndex: "service",
          key: "service",
          width: "120",
        },
        {
          title: "Revenue Code",
          dataIndex: "revenueCode",
          key: "revenueCode",
          width: "90",
        },
        {
          title: "Transaction Amount",
          dataIndex: "transactionAmount",
          key: "transactionAmount",
          width: "130",
          sorter: (a, b) =>
            a.transactionAmount.replace(",", "") -
            b.transactionAmount.replace(",", ""),
        },
      ],
    },
    {
      title: "Charges",
      children: [
        {
          title: "Processing Fee",
          dataIndex: "processingFee",
          key: "processingFee",
          width: "100",
          sorter: (a, b) =>
            a.processingFee.replace(",", "") - b.processingFee.replace(",", ""),
        },
        {
          title: "VAT Fee",
          dataIndex: "vatFee",
          key: "vatFee",
          width: "130",
          sorter: (a, b) =>
            a.vatFee.replace(",", "") - b.vatFee.replace(",", ""),
        },
        {
          title: "Total Fee",
          dataIndex: "totalFee",
          key: "totalFee",
          width: "130",
          sorter: (a, b) =>
            a.totalFee.replace(",", "") - b.totalFee.replace(",", ""),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "Net Amount",
          dataIndex: "netAmount",
          key: "netAmount",
          width: "120",
          sorter: (a, b) =>
            a.netAmount.replace(",", "") - b.netAmount.replace(",", ""),
        },
        {
          title: "Cumulative Net Amount",
          dataIndex: "cumulativeNetAmount",
          key: "cumulativeNetAmount",
          width: "150",
          sorter: (a, b) =>
            a.cumulativeNetAmount.replace(",", "") -
            b.cumulativeNetAmount.replace(",", ""),
        },
        {
          title: "GL Account",
          dataIndex: "glAccount",
          key: "glAccount",
          width: "80",
        },
        {
          title: "Currency",
          dataIndex: "currency",
          key: "currency",
          width: "80",
        },
        {
          title: "Purpose",
          dataIndex: "purpose",
          key: "purpose",
          width: "150",
        },
      ],
    },
    {
      title: "Beneficiary Details",
      children: [
        {
          title: "Bank Name",
          dataIndex: "bankName",
          key: "bankName",
          width: "110",
        },
        {
          title: "Account Name",
          dataIndex: "accountName",
          key: "accountName",
          width: "125",
        },
        {
          title: "Account Classification",
          dataIndex: "accountClassification",
          key: "accountClassification",
          width: "150",
        },
        {
          title: "Account Number",
          dataIndex: "accountNumber",
          key: "accountNumber",
          width: "140",
        },
      ],
    },
    {
      title: "Payment Gateway Details",
      children: [
        {
          title: "Payment Gateway Name",
          dataIndex: "paymentGatewayName",
          key: "paymentGatewayName",
          width: "160",
        },
        {
          title: "Payment Channel",
          dataIndex: "paymentChannel",
          key: "paymentChannel",
          width: "110",
        },
      ],
    },
    {
      title: "Payment Details",
      children: [
        {
          title: "Payer Name",
          dataIndex: "payerName",
          key: "payerName",
          width: 200,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 200,
        },
        {
          title: "Phone no",
          dataIndex: "phoneNo",
          key: "phoneNo",
          width: 150,
        },
      ],
    },
    {
      title: "",
      children: [
        // {
        //   title: "Payment Reference",
        //   dataIndex: "paymentReference",
        //   key: "paymentReference",
        //   width: "120",
        // },
        {
          title: "Payment Status",
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          render: (_, { paymentStatus }) => (
            <>
              <Tag color="green" key={paymentStatus}>
                {paymentStatus}
              </Tag>
            </>
          ),
        },
      ],
    },
  ];

  const [searchText, setSearchText] = useState("");

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const transformedFundingData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.accountName === null ||
            obj?.accountName === undefined ||
            obj?.accountName === "N/A"
          ) {
            return null;
          }

          return obj;
        })
        .filter((obj: any) => obj !== null)
    )
  ).map((obj) => ({
    value: obj.accountNumber,
    label: `${obj.accountNumber}-${obj.accountName}`,
  }));
  const uniqueTransformedFundingData: any = Array.from(
    new Set(transformedFundingData?.map((obj: any) => obj.value))
  ).map((value) =>
    transformedFundingData?.find((obj: any) => obj.value === value)
  );

  // const transformedData = Array.from(
  //   new Set(bankData?.map((item: any) => item))
  // ).map((item: any) => ({
  //   value: item.accountNumber,
  //   label: `${item.accountNumber}-${item.accountName}`,
  // }));

  const { data: mdaList, isLoading } = useGetMdaWithService();

  const [account, setAccount] = useState<string>("");
  const [srv, setSrv] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [orgId, setOrgId] = useState<string>("");
  const [accountType, setAccountType] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [paymentChannelF, setPaymentChannelF] = useState<string>("");
  const [paymentGatewayF, setPaymentGatewayF] = useState<string>("");
  const [revcode, setRevcode] = useState<string>("");
  const [payerName, setPayerName] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [grr, setGrr] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [refreshFilter, setRefreshFilter] = useState<boolean>(false);
  const [hasBnFiltered, setHasBnFiltered] = useState<boolean>(false);

  const fetchFilter = () => {
    let searchFilter = "";
    if (account) searchFilter += `src-acct-num=${account}&`;
    if (srv) searchFilter += `service=${srv}&`;
    if (grr) searchFilter += `invoice-number=${grr}&`;
    if (paymentGatewayF) searchFilter += `gateway=${paymentGatewayF}&`;
    if (payerName) searchFilter += `payer=${payerName}&`;
    if (startDate) searchFilter += `start-date=${startDate}&`;
    if (endDate) searchFilter += `end-date=${endDate}&`;
    // if (orgId) searchFilter += `org-id=${orgId}&`;
    if (revcode) searchFilter += `rev-code=${revcode}&`;
    if (accountType) searchFilter += `account-type=${accountType}&`;
    if (reference) searchFilter += `payment-ref=${reference}&`;
    const startAmount = amountFilter.split("-")[0] || "";
    if (startAmount) searchFilter += `start-amount=${startAmount}&`;
    const endAmount = amountFilter.split("-")[1] || "";
    if (endAmount) searchFilter += `end-amount=${endAmount}&`;
    if (status) searchFilter += `status=${status}`;

    // Remove the trailing '&' if the searchFilter is not empty
    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }

    setSearchFilterValue(searchFilter)
    const onSuccess = () => {
      setHasBnFiltered(true);
      setRefreshFilter(false);
    };
    fetchTableData(
      mutateInFlowData,
      tableParams,
      setTableParams,
      searchFilter,
      onSuccess
    );
  };

  const updateFilter = (value: string, fn: any) => {
    fn(value);
    if (value.length === 0 && !refreshFilter && hasBnFiltered) {
      setRefreshFilter(true);
      setHasBnFiltered(false);
    } else {
      setRefreshFilter(false);
      setHasBnFiltered(false);
    }
  };

  const resetFilterdata = () => {
    setAccount("");
    setSrv("");
    setGrr("");
    setStatus("");
    setAccountType("");
    setCurrency("");
    setPaymentChannelF("");
    setPaymentGatewayF("");
    setReference("");
    setPayerName("");
    setRevcode("");
    setOrgId("");
    setAmountFilterOptions([]);
    setAmountFilter("");
    setStartDate("");
    setEndDate("");
    setDateRange([]);
  };

  const resetFilter = () => {
    fetchTableDataReset(
      mutateInFlowData,
      tableParams,
      setTableParams,
      resetFilterdata
    );
  };

  useEffect(() => {
    if (refreshFilter) {
      fetchFilter();
    }
  }, [refreshFilter]);

  const paymentStatusList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.paymentStatus;
        })
        .filter((paymentStatus: any) => paymentStatus !== null)
    )
  ).map((paymentStatus) => ({
    value: paymentStatus,
    label: convertToFirstLetterCap(paymentStatus),
  }));

  const transformedServiceData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.service;
        })
        .filter((obj: any) => obj?.service !== null)
    )
  ).map((service: any) => ({
    value: service,
    label: service,
  }));

  const transformedpaymentGateway = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.paymentGatewayName === null ||
            obj?.paymentGatewayName === undefined ||
            obj?.paymentGatewayName === "N/A"
          ) {
            return null;
          }
          return obj?.paymentGatewayName;
        })
        .filter((paymentGatewayName: any) => paymentGatewayName !== null)
    )
  ).map((paymentGatewayName: any) => ({
    value: paymentGatewayName,
    label: paymentGatewayName,
  }));

  const transformedAccountTypeData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.accountClassification;
        })
        .filter((accountClassification: any) => accountClassification !== null)
    )
  ).map((accountClassification) => ({
    value: accountClassification,
    label: accountClassification,
  }));

  const grrList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj["Government Revenue Reference (GRR)"] === null ||
            obj["Government Revenue Reference (GRR)"] === undefined
          ) {
            return null;
          }
          return obj["Government Revenue Reference (GRR)"];
        })
        .filter((item: null) => item !== null)
    )
  ).map((item) => ({
    value: item,
    label: item,
  }));

  // const paymentReferenceList = Array.from(
  //   new Set(
  //     dataSource
  //       .map((obj: { paymentReference: null | undefined }) => {
  //         if (
  //           obj?.paymentReference === null ||
  //           obj?.paymentReference === undefined
  //         ) {
  //           return null;
  //         }
  //         return obj.paymentReference;
  //       })
  //       .filter((paymentReference: null) => paymentReference !== null)
  //   )
  // ).map((paymentReference) => ({
  //   value: paymentReference,
  //   label: paymentReference,
  // }));

  const payerNameList = Array.from(
    new Set(
      dataSource
        .map((obj: { payerName: null | undefined }) => {
          if (obj?.payerName === null || obj?.payerName === undefined) {
            return null;
          }
          return obj.payerName;
        })
        .filter((payerName: null) => payerName !== null)
    )
  ).map((payerName) => ({
    value: payerName,
    label: payerName,
  }));

  const [dateRange, setDateRange] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      setStartDate(date1);
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");

      setEndDate(date2);
    } else {
      setDateRange([]);
    }
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    if (canLoad) {
      fetchFilter();
    }
  }, [JSON.stringify(tableParams.pagination.current)]);
  return (
    <>
      {isLoading || allMdaLoading ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div className={"reviewerDashboard"}>
          <div className={"ciBreadcrumb"}>
            <div>Reports</div>
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>
            <div>Inflow</div>
          </div>

          <div className={"ciRow1"}>
            <div className={"ministryOfEduText"}>
              {" "}
              {lazydayUser?.registeredName}
              {/* <Select
                dropdownMatchSelectWidth={false}
                  showSearch
                  style={{ width: 200, border: 'none', overflowY: 'auto' }}
                  placeholder="Select  MDA"
                  optionFilterProp="children"
                  onSearch={handleSearch}
                  onChange={(e) => getOrganisationData(e)}
                  filterOption={(input, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {mdaList &&
                    mdaList?.map((mda: any, i: any) => (
                      <option value={mda.id} key={i}>
                        {mda?.registeredName}
                      </option>
                    ))}
                </Select> */}
            </div>

            <div>
              <div>
                <div className={"ciTitle"}>Report Date & Time</div>
                <div className={"ciValue"}> {FormatDate()} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Administrative Code</div>
                <div className={"ciValue"}>{lazydayUser?.mdaAdminCode} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Total Receipts</div>
                <div className={"ciValue"}>
                  {tableParams?.pagination?.total}
                </div>
              </div>

              {/*<div>*/}
              {/*  <div className={"ciTitle"}>MDA Email</div>*/}
              {/*  <div className={"ciValue"}> {lazydayUser?.contact?.email} </div>*/}
              {/*</div>*/}

              {/*<div>*/}
              {/*  <div className={"ciTitle"}>MDA Phone number</div>*/}
              {/*  <div className={"ciValue"}>*/}
              {/*    {lazydayUser?.contact?.phoneNumber}{" "}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className={"reviewerTableContainer"}>
            <div className="d-flex justify-content-end  mb-9 me-4">
              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={
                  lazydayUser?.registeredName + " - Collection Inflow Report"
                }
                pdfFileTitle={
                  lazydayUser?.registeredName + " - Collection Inflow Report"
                }
                excelPageTitle={
                  lazydayUser?.registeredName + " - Collection Inflow Report"
                }
                grandTotalFor={{
                  transactionAmount: "Transaction Amount",
                  processingFee: "Processing Fee",
                  vatFee: "VAT Fee",
                  totalFee: "Total Fee",
                  netAmount: "Net Amount",
                  cumulativeNetAmount: "Cumulative Net Amount",
                }}
              />
            </div>

            <div
              className={"ciSelectOptions"}
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >

              <DropDown
                mutate={mdaMutate}
                value={orgId}
                labelKey="registeredName"
                valueKey="id"
                apiData={mdaNameList}
                onChange={(e) => updateFilter(e, setOrgId)}
                width={250}
                placeholder="Search MDA Name"
                valueStyles={{
                  paddingLeft: "0.5rem",
                }}
                controlStyles={{
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  height: 32,
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                }}
                height={30}
                loading={allMdaLoading}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 200 }}
                options={[
                  { value: "", label: "Receiving Account" },
                  ...uniqueTransformedFundingData,
                ]}
                onChange={(e) => updateFilter(e, setAccount)}
                showSearch
                className="border border-gray-800 my-0 py-0 rounded-2"
                value={account}
              />

              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Service"
                style={{ width: 130 }}
                options={[
                  { value: "", label: "Service" },
                  ...transformedServiceData,
                ]}
                showSearch
                className="border border-gray-800 my-0 py-0 rounded-2"
                onChange={(e) => updateFilter(e, setSrv)}
                value={srv}
              />

              {/* <Select
                dropdownMatchSelectWidth={false}
                  defaultValue="Payment Type"
                  style={{ width: 130 }}
                  options={[
                    { value: "", label: "Payment Type" },

                  ]}
                /> */}

              {/* <Select
                dropdownMatchSelectWidth={false}
                  defaultValue="Expenditure Head/Subhead"
                  style={{ width: 210 }}
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                    { value: "Yiminghe", label: "yiminghe" },
                    { value: "disabled", label: "Disabled", disabled: true },
                  ]}
                /> */}

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 170 }}
                options={[
                  { value: "", label: "Account Classification" },
                  ...transformedAccountTypeData,
                ]}
                onChange={(e) => updateFilter(e, setAccountType)}
                placeholder="Account Classification"
                value={accountType}
              />

              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Amount Range"
                placeholder={"Filter by Amount"}
                style={{ minWidth: 234 }}
                onChange={(e) => updateFilter(e, setAmountFilter)}
                options={[
                  { value: "", label: "Amount" },
                  ...amountFilterOptions,
                ]}
                value={amountFilter}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 100 }}
                options={[
                  { value: "", label: "Currency" },
                  { value: "NGN", label: "NGN" },
                ]}
                onChange={(e) => updateFilter(e, setCurrency)}
                placeholder="Currency"
                value={currency}
              />

              <Space direction="vertical" size={12}>
                <RangePicker
                  className="py-1 fs-8 fw-bold"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                  size={"small"}
                />
              </Space>

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 162 }}
                className="border border-gray-800 my-0 py-0 rounded-2"
                options={[
                  { value: "", label: "Government Revenue Reference (GRR)" },
                  ...grrList,
                ]}
                showSearch
                onChange={(e) => updateFilter(e, setGrr)}
                placeholder="Government Revenue Reference (GRR)"
                value={grr}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 150 }}
                options={[
                  { value: "", label: "Payment Gateway" },
                  ...transformedpaymentGateway,
                ]}
                onChange={(e) => updateFilter(e, setPaymentGatewayF)}
                placeholder="Payment Gateway"
                value={paymentGatewayF}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 150 }}
                options={[{ value: "", label: "Payment Channel" }]}
                onChange={(e) => updateFilter(e, setPaymentChannelF)}
                placeholder="Payment Channel"
                value={paymentChannelF}
              />

              {/* <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 162 }}
                className="border border-gray-800 my-0 py-0 rounded-2"
                options={[
                  { value: "", label: "Payment Reference" },
                  ...paymentReferenceList,
                ]}
                showSearch
                onChange={(e) => updateFilter(e, setReference)}
                placeholder="Payment Account"
                value={reference}
              /> */}
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 160 }}
                options={[{ value: "", label: "Payer Name" }, ...payerNameList]}
                onChange={(e) => updateFilter(e, setPayerName)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                placeholder="Payer Name"
                value={payerName}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 150 }}
                onChange={(e) => updateFilter(e, setStatus)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                options={[
                  {
                    value: "",
                    label: "Transaction Status",
                  },
                  ...paymentStatusList,
                ]}
                value={status}
              />

              <button onClick={fetchFilter}>
                <span>Apply Filter</span>

                <img src={filter} alt="" />
              </button>

              <div onClick={resetFilter} className="cursor-pointer">
                <span className="fs-9 text-primary fw-bold">Reset Filter</span>
              </div>
            </div>

            <div style={{ overflowX: "auto" }} className="mt-9">
              <Table
                dataSource={dataSource}
                bordered
                columns={columns}
                scroll={{ x: "5000px" }}
                rowKey={(record:any) => record?.sn}
                pagination={tableParams.pagination}
                loading={{
                  spinning: !!isLoadingInFlowData,
                  indicator: <Loader />,
                }}
                onChange={(e) => handleTableChange(e)}
              />

              {/* <TableComponent
                dataSource={dataSource}
                columns={columns}
                isLoading={isLoadingInFlowData}
                mutateTable={mutateInFlowData}
                searchFilter={searchFilterValue}
                width={5000}
              /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CollectionInflowOAGF;
