/* eslint-disable array-callback-return */
import { ErrorToast } from 'app/components/toast';
import { money } from 'core/assets/imgs';
import { LoaderContainer } from 'core/helpers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { isAllowedNumeric } from '../revenuePayments/components/UsefulFn';

interface PaymentSummaryProps {
    invoiceData?: any;
    setStep: () => void;
    setInvoiceData: (e: any) => void;
    setShow: () => void;
    paymentMutate: any;
    paramsData?: boolean;
    amount?: number;
    setAmount?: (e: number) => void;
    payListData: any;
    setLoading?: (e: boolean) => void;
    loading?: boolean;
    confirmSuccessfulPaymentMutate: any;
}

declare global {
    interface Window {
        RmPaymentEngine: any;
    }
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
    invoiceData,
    setStep,
    setShow,
    paymentMutate,
    setInvoiceData,
    paramsData,
    amount,
    setAmount,
    payListData,
    setLoading,
    loading = false,
    confirmSuccessfulPaymentMutate,
}) => {
    const navigate = useNavigate();
    const [checkFull, setCheckFull] = useState<boolean>(false);

    const [paymentType, setPaymentType] = useState<string | null>('Remita');
    const [partAmount, setPartAmount] = useState<number | undefined | null>(null);
    const [balanceDue, setBalanceDue] = useState<number>(0);
    useEffect(() => {
        if (amount) {
            setPartAmount(amount);
        }
    }, [amount]);

    const done = () => {
        navigate('/');
    };

    const Proceed = () => {
        const data = {
            invoiceNumber: invoiceData?.invoiceNumber,
            amount: invoiceData?.installPayment ? partAmount : invoiceData?.amount,
            paymentGateway: paymentType,
            paymentChannel: '',
        };

        const data2 = {
            email: invoiceData?.payer?.email,
            amount: invoiceData?.amount,
            customerId: invoiceData?.invoiceNumber,
            transactionRef: invoiceData?.invoiceNumber,
            currency: 'NGN',
        };

        if (paymentType && paymentType?.length > 0) {
            if (paymentType === 'VoxePay') {
                navigate('/voxePayment', { state: { data: data2 } });
            }
            paymentMutate(data, {
                onSuccess: (response: any) => {
                    makePayment(response?.transactionId);
                },
            });
        } else {
            ErrorToast('Choose a service provider');
        }
    };

    const updatePartAmount = (e: any) => {
        const { floatValue } = e;
        setPartAmount(floatValue);
        if (setAmount) {
            setAmount(floatValue);
        }
    };

    useEffect(() => {
        if (checkFull) {
            setPartAmount(Number(invoiceData?.balanceDue));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkFull]);
    useEffect(() => {
        let total: number = 0;
        invoiceData?.paymentHistory?.forEach((item) => {
            if (item?.status.toLowerCase() === 'paid' && item?.amountInfo?.amount) {
                total += item.amountInfo.amount;
            }
        });
        setBalanceDue(Number(total));
    }, [invoiceData]);

    const [disable, setDisable] = useState<boolean>(true);
    useEffect(() => {
        if (invoiceData?.installPayment) {
            if (partAmount === null || paymentType === null) {
                setDisable(true);
                return;
            }
            if (partAmount === undefined && partAmount !== null && Number(partAmount) !== 0) {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else {
            setDisable(false);
        }
    }, [partAmount, invoiceData?.installPayment, invoiceData?.amount, paymentType]);

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://remitademo.net/payment/v1/remita-pay-inline.bundle.js';
        script.async = true;
        script.onload = () => {
            setScriptLoaded(true);
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const makePayment = (transactionId) => {
        if (window.RmPaymentEngine) {
            const paymentEngine = window.RmPaymentEngine.init({
                key: 'QzAwMDAyNzEyNTl8MTEwNjE4NjF8OWZjOWYwNmMyZDk3MDRhYWM3YThiOThlNTNjZTE3ZjYxOTY5NDdmZWE1YzU3NDc0ZjE2ZDZjNTg1YWYxNWY3NWM4ZjMzNzZhNjNhZWZlOWQwNmJhNTFkMjIxYTRiMjYzZDkzNGQ3NTUxNDIxYWNlOGY4ZWEyODY3ZjlhNGUwYTY=',
                transactionId: transactionId,
                customerId: invoiceData?.payer?.email,
                firstName: invoiceData?.payer?.fullName.split(' ')[0],
                lastName: invoiceData?.payer?.fullName.split(' ')[1],
                email: invoiceData?.payer?.email,
                amount: invoiceData?.installPayment ? partAmount : invoiceData?.amount,
                narration: invoiceData?.amountInfo?.description ?? 'Payment from Treasury Management System',
                onSuccess: (response: any) => {
                    confirmSuccessfulPaymentMutate(transactionId, {
                        onSuccess: (response: any) => {
                            setLoading?.(false);
                            navigate(`/revenuepayment/success?trxref=${transactionId}&reference=${transactionId}`, {
                                replace: true,
                                state: response,
                            });
                        },
                        onError: () => {
                            setLoading?.(false);
                        },
                    });
                },
                onError: (response: any) => {
                    setLoading?.(false);
                    ErrorToast('Payment Processing Failed, try again');
                },
                onClose: () => {
                    if (!loading) {
                        setLoading?.(false);
                    }
                },
            });

            setLoading?.(true);

            paymentEngine.showPaymentWidget();
        } else {
            ErrorToast('Payment gateway is not ready yet, please try again.');
            //setPageLoading(false)
        }
    };

    console.log('ddd', loading);

    return (
        <>
            {loading && <LoaderContainer />}
            <div className="card m-auto i py-6 responsive-card">
                <div className="invoice-card-no-top">
                    <div className="d-flex justify-content-center align-items-center ">
                        <div className="d-flex flex-column justify-content-center align-items-center pb-3">
                            <span className="text-dark fs-10 lh-lg fw-bolder  py-1 px-1 rounded-sm">
                                Government Revenue Reference (Invoice)
                            </span>

                            <span className="text-success fs-10 lh-lg fw-bolder bg-light-success py-1 px-1 rounded-sm">
                                {invoiceData?.invoiceNumber}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center ">
                        <div className="d-flex justify-content-center align-items-center ">
                            <span className="text-dark fs-5 fw-bolder lh-lg">Payment Summary</span>
                        </div>
                    </div>

                    <hr className="text-muted h-1px" />
                    <div>
                        <div className="d-flex justify-content-between py-3">
                            <span className="text-muted fs-9 fw-bold text-start min-w-25">MDA</span>
                            <span className="text-dark fs-9 fw-bold  overflow-hidden">{invoiceData?.orgName}</span>
                        </div>

                        <div className="d-flex justify-content-between py-3">
                            <span className="text-muted fs-9 fw-bold text-start min-w-25">Service</span>
                            <span className="text-dark fs-9 fw-bold  overflow-hidden">{invoiceData?.serviceName}</span>
                        </div>

                        {invoiceData?.amountInfo?.description.length > 0 && (
                            <div className="d-flex justify-content-between py-3">
                                <span className="text-muted fs-9 fw-bold text-start min-w-25">Description</span>
                                <span className="text-dark fs-9 fw-bold  overflow-hidden">
                                    {invoiceData?.amountInfo?.description}
                                </span>
                            </div>
                        )}

                        <div className="d-flex justify-content-between py-3">
                            <span className="text-muted fs-9 fw-bold text-start min-w-25">Full Name</span>
                            <span className="text-dark fs-9 fw-bold  overflow-hidden">
                                {invoiceData?.payer?.fullName}
                            </span>
                        </div>

                        <div className="d-flex justify-content-between py-3">
                            <span className="text-muted fs-9 fw-bold text-start min-w-25">Email</span>
                            <span className="text-dark fs-9 fw-bold  overflow-hidden">{invoiceData?.payer?.email}</span>
                        </div>

                        <div className="d-flex justify-content-between py-3">
                            <span className="text-muted fs-9 fw-bold text-start min-w-25">Phone Number</span>
                            <span className="text-dark fs-9 fw-bold  overflow-hidden">{invoiceData?.payer?.phone}</span>
                        </div>

                        {invoiceData?.payer?.customValues?.map((customData: any, index: any) => {
                            if (customData?.value === null && customData?.selectOptions?.length === 0) {
                                // eslint-disable-next-line array-callback-return
                                return;
                            }
                            return (
                                <div
                                    className="d-flex justify-content-between py-3"
                                    key={`${customData?.field}-${index}`}
                                >
                                    <span className="text-muted fs-9 fw-bold text-start min-w-35">
                                        {customData?.field}
                                    </span>

                                    {customData?.selectOptions?.length === 0 ? (
                                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                                            {customData?.value}
                                        </span>
                                    ) : (
                                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                                            {customData?.selectOptions?.map((item: any) => item.label).join(', ')}
                                        </span>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    {invoiceData?.payer?.paymentComponentList?.length >= 1 &&
                        invoiceData?.payer?.paymentComponentList[0]?.name !== invoiceData?.serviceName && (
                            <>
                                <hr className="text-muted h-1px" />
                                <div className="d-flex justify-content-between">
                                    <span className="text-dark fs-9 fw-bolder text-start min-w-25">
                                        Service Item(s)
                                    </span>
                                </div>
                                {invoiceData?.payer?.paymentComponentList?.map((customData: any, index: any) => {
                                    if (invoiceData?.serviceName === customData?.name) {
                                        return;
                                    }
                                    return (
                                        <div className="d-flex justify-content-between pt-3" key={index}>
                                            <span className="text-muted fs-10 fw-bold text-start min-w-25">
                                                {customData?.name}
                                            </span>
                                            <span className="text-dark fs-10 fw-bold ">
                                                {/* {formik.values.service} */}N{customData?.amount.toLocaleString()}
                                            </span>
                                        </div>
                                    );
                                })}
                            </>
                        )}

                    <div className="d-flex justify-content-between mt-3 py-3  border-gray-800 border-top-dashed ">
                        <span className="text-muted fs-10 fw-bold text-start min-w-25">
                            {invoiceData?.payer?.paymentComponentList?.length > 1 || invoiceData?.installPayment
                                ? 'Total'
                                : ''}{' '}
                            Amount {invoiceData?.installPayment ? 'Payable' : ''}
                        </span>
                        <span className="text-dark fs-10 fw-bold  overflow-hidden">
                            N{Number(invoiceData?.amount).toLocaleString()}
                        </span>
                    </div>

                    {invoiceData?.installPayment && (
                        <>
                            <div className="d-flex justify-content-between py-3  border-gray-800 border-top-dashed ">
                                <span className="text-muted fs-10 fw-bold text-start min-w-25">Instalment Paid</span>
                                <span className="text-dark fs-10 fw-bold  overflow-hidden">
                                    N{Number(balanceDue).toLocaleString()}
                                </span>
                            </div>

                            <div className="d-flex justify-content-between py-3  border-gray-800 border-top-dashed ">
                                <span className="text-muted fs-10 fw-bold text-start min-w-25">Balance Due</span>
                                <span className="text-dark fs-10 fw-bold  overflow-hidden">
                                    N{Number(invoiceData?.balanceDue).toLocaleString()}
                                </span>
                            </div>

                            {/* <div className="table-responsive mb-4">
                <table className="table align-middle  gx-0 gy-1 mt-1">
                  <thead className="bg-white">
                    <tr>
                      <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold ps-0">
                        Amount Paid
                      </th>
                      <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                        Payment Reference
                      </th>
                      <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                        Date
                      </th>
                      <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                        Balance Due
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {invoiceData?.paymentHistory?.map(
                      (payData: any, index: any) => {
                        if (payData.status.toLowerCase() === "new") {
                          return;
                        }
                        return (
                          <tr key={index}>
                            <td className="text-dark fs-8  text-start border-0 capitaliseText ps-0">
                              ₦
                              {Number(
                                payData?.amountInfo?.amount
                              ).toLocaleString()}
                            </td>
                            <td className="text-dark fs-8  text-start capitaliseText border-0">
                              {payData?.paymentReference}
                            </td>
                            <td className="text-dark fs-8  text-start border-0">
                              {moment(payData?.paymentDate).format(
                                "MMM Do YYYY, h:mm a"
                              )}
                            </td>
                            <td className="text-dark fs-8  text-start border-0">
                              ₦
                              {Number(
                                payData?.amountInfo?.amount
                              ).toLocaleString()}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div> */}
                        </>
                    )}
                </div>

                {invoiceData?.paymentHistory?.length > 0 && (
                    <div className="table-responsive  m-auto">
                        <table className="table align-middle gs-0  ">
                            <thead className="bg-white">
                                <tr>
                                    <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                                        Amount Paid
                                    </th>
                                    <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                                        Payment Reference
                                    </th>
                                    <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                                        Date
                                    </th>
                                    <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                                        Balance Due
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {invoiceData?.paymentHistory?.map((payData: any, index: any) => {
                                    if (payData.status.toLowerCase() === 'new') {
                                        return;
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className="text-dark fs-10  text-center border-0 capitaliseText p-2">
                                                ₦{Number(payData?.amountInfo?.amount).toLocaleString()}
                                            </td>
                                            <td className="text-dark fs-10  text-center capitaliseText border-0 p-2">
                                                {payData?.paymentReference}
                                            </td>
                                            <td className="text-dark fs-10  text-center border-0 p-2">
                                                {/* "Do-MMMM-YYYY, h:mm a" */}
                                                {moment(payData?.paymentDate).format('Do-MMMM-YYYY')}
                                            </td>
                                            <td className="text-dark fs-10  text-center border-0 p-2">
                                                ₦{Number(payData?.currentBalanceDue).toLocaleString()}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {invoiceData?.installPayment && (
                <div className="card  m-auto invoice-card py-7 responsive-card mt-11">
                    <div>
                        <span className="text-muted required fs-9">How much do you want to pay (Part Payment)</span>

                        <div
                            className="d-flex align-items-center h-2r py-2 cursor-pointer"
                            onClick={() => setCheckFull(!checkFull)}
                            role="none"
                        >
                            <span className="form-check form-check-custom ">
                                <input
                                    type="checkbox"
                                    className="form-check-input w-1r h-1r rounded-1 form-check-input-alt"
                                    name="fullPayment"
                                    checked={partAmount === Number(invoiceData?.balanceDue)}
                                    onChange={() => setCheckFull(!checkFull)}
                                />
                                <span className="text-muted fs-9 ms-3">Full Payment</span>
                            </span>
                        </div>

                        <div className={`mt-1`}>
                            <div className={`form-control form-control-solid`}>
                                <img src={money} alt="" className="icon-style" />

                                <NumericFormat
                                    type="text"
                                    prefix="₦"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    className="no-outline text-dark ps-4 fs-8"
                                    value={amount || partAmount}
                                    placeholder="Enter Amount"
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                    decimalScale={2}
                                    isAllowed={(e: any) => isAllowedNumeric(e, Number(invoiceData?.balanceDue))}
                                    onValueChange={(event) => updatePartAmount(event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="card  m-auto invoice-card py-7 responsive-card mt-11">
                <div>
                    {/* <span className="text-dark fs-5 fw-bolder ">Choose Service Provider</span>
                    <hr className="text-muted h-1px" />

                    <div className="grid-3C ">
                        {payListData?.map((data: any, index: any) => {
                            if (!data.active) {
                                return;
                            }
                            return (
                                <div className="cursor-pointer" onClick={() => setPaymentType(data?.name)} key={index}>
                                    <span className="form-check form-check-custom form-check-solid">
                                        <input
                                            type="radio"
                                            className="form-check-input w-1r1 h-1r1"
                                            name={data?.name}
                                            checked={paymentType === data?.name}
                                            value={data?.name}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                        />
                                        <div className="bg-white rounded ms-1">
                                            <img src={data?.imageUrl} alt="" className="payment-gateway-img" />
                                        </div>
                                    </span>
                                </div>
                            );
                        })}

                        <div className="cursor-pointer" onClick={() => setPaymentType('VoxePay')} key={7}>
                            <span className="form-check form-check-custom form-check-solid">
                                <input
                                    type="radio"
                                    className="form-check-input w-1r1 h-1r1"
                                    name={'VoxePay'}
                                    checked={paymentType === 'VoxePay'}
                                    value={'VoxePay'}
                                    onChange={(e) => setPaymentType(e.target.value)}
                                />
                                <div className="bg-white rounded ms-1">
                                    <img src={voxepay} alt="" className="payment-gateway-img" />
                                </div>
                            </span>
                        </div>
                    </div>

                    <hr className="text-muted h-1px" /> */}

                    <div className="d-flex  justify-content-between mt-5">
                        <div>
                            {paramsData ? (
                                <button
                                    id="kt_password_cancel "
                                    type="button"
                                    className="btn btn-danger px-7 py-2 text-alt-red "
                                    onClick={done}
                                >
                                    <span className="indicator-label fs-base ">Cancel</span>
                                </button>
                            ) : (
                                <button
                                    id="kt_password_cancel "
                                    type="button"
                                    className="btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success"
                                    onClick={() => !loading && setStep()}
                                    disabled={loading}
                                >
                                    <span className="indicator-label fs-base text-success">Back</span>
                                </button>
                            )}
                        </div>

                        {/* <div>
                            <button
                                className={`btn ${
                                    disable ? 'btn-secondary' : 'btn-success'
                                } text-white px-7 py-2 h-44 w-110 rounded-1`}
                                onClick={() => Proceed()}
                                disabled={disable}
                            >
                                <span className="indicator-label fs-base">Pay</span>
                            </button>
                        </div> */}

                        <div>
                            <button
                                className={`btn ${
                                    disable ? 'btn-secondary w-126' : 'btn-success'
                                } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
                                onClick={() => !(!scriptLoaded || disable || loading) && Proceed()}
                                disabled={!scriptLoaded || disable || loading}
                            >
                                {scriptLoaded ? 'Pay' : 'Loading Gateway...'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PaymentSummary;
