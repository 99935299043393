import { AppLogo } from 'core/assets/imgs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../App.css';

const NavBarSignup = () => {
    const [sidebar, setSidebar] = useState(false);
    const [navText, setNav] = useState('');
    const navigate = useNavigate();

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        (e: any) => {
            const window = e.currentTarget;

            if (window.pageYOffset > 80) {
                setNav('navbar-fixed');
            } else {
                setNav('');
            }
            setY(window.scrollY);
        },
        [y],
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    const redirecLogin = () => {
        navigate('/auth/login');
    };

    const redirecSignup = () => {
        navigate('/auth/register');
    };

    return (
        <div>
            <nav className={`nav navbar-fixed-signup`} id="nav">
                <div className="nav-center-signup">
                    {/* Nav header */}
                    <div className="nav-header-signup">
                        <img src={AppLogo} className="nav-logo" alt="nav logo" />
                        <button className="nav-btn-signup " id="nav-btn" onClick={() => navigate(-1)}>
                            Back To Home
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavBarSignup;
