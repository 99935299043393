import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import userAvatar from 'core/assets/imgs/dashboard/avatar.jpg';
import React from 'react';
import '../../../App.css';
import { useGetLogOut } from '../../pages/auth/hooks/useUser';
// import { clearStoredUser, getStoredUser, setStoredUser } from '../user-storage';
import { FetchAndUnmask, MaskAndSave } from 'core/helpers';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { clearStoredUser } from '../../pages/auth/user-storage';
import { queryKeys } from '../../react-query/constants';
import Drop from './Drop';
import { AppLogo } from 'core/assets/imgs';

interface Step1Props {
    showMenu: () => void;
}

const Header: React.FC<Step1Props> = ({ showMenu }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { data, mutate, isLoading } = useGetLogOut();

    const lazydayUser = JSON.parse(FetchAndUnmask('lazyday_user') as string);

    const menuItems = lazydayUser?.organizationUser?.resource;

    const logOut = () => {
        clearStoredUser();
        queryClient.setQueriesData(queryKeys.user, null);

        mutate({
            onSuccess: () => {},
            onError: (err: any) => {},
        });
        MaskAndSave('userLogin', 'false');
        navigate('/auth/login');
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div onClick={logOut}>Logout</div>,
        },
    ];

    function getUserName() {
        const lazydayUser = JSON.parse(FetchAndUnmask('lazyday_user') as string);
        return `${lazydayUser?.organizationUser?.firstName} ${lazydayUser?.organizationUser?.lastName}`;
    }

    const itemsNotification: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    1st menu item
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    2nd menu item (disabled)
                </a>
            ),
            icon: (
                <SmileOutlined
                    onPointerOverCapture={undefined}
                    onPointerMoveCapture={undefined}
                />
            ),
            disabled: true,
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    3rd menu item (disabled)
                </a>
            ),
            disabled: true,
        },
        {
            key: '4',
            danger: true,
            label: 'a danger item',
        },
    ];
    return (
        <div className={'mainHeader'}>
            <div>
                <img className={'headerLogo'} src={AppLogo} alt="Office of The Accountant General of The Federation" />
            </div>

            <div className="mda-name">
                {' '}
                <span>{lazydayUser?.registeredName}</span>
            </div>

            <div className={'headerLeftNav'}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.578125" width="24" height="24" rx="2" fill="#FEF5D7" />
                    <path
                        d="M3 5.82812H21M3 5.82812V18.5781C3 18.777 3.07902 18.9678 3.21967 19.1085C3.36032 19.2491 3.55109 19.3281 3.75 19.3281H20.25C20.4489 19.3281 20.6397 19.2491 20.7803 19.1085C20.921 18.9678 21 18.777 21 18.5781V5.82812M3 5.82812L12 14.0781L21 5.82812"
                        stroke="#1F8505"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>

                <Drop />

                <div className={'headerUsernameRole'}>
                    <div>Hi, {getUserName()}</div>
                    <div className={'role'}>Admin</div>
                </div>

                <div className={'headerUserAvatar'}>
                    <img src={userAvatar} alt="user avatar" />
                    <Dropdown menu={{ items }}>
                        <a onClick={(e) => e.preventDefault()}>
                            <DownOutlined
                                onPointerOverCapture={undefined}
                                onPointerMoveCapture={undefined}
                            />
                        </a>
                    </Dropdown>
                </div>
            </div>

            <button className={'mobileMenuTrigger'} onClick={showMenu}>
                <i className={'fas fa-bars'}></i>
            </button>
        </div>
    );
};

export default Header;
