import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { edit } from 'core/assets/icons';
import { useNavigate } from 'react-router-dom';

import { useGetRequestOnboarding } from '../hooks/useUser';
import { log } from 'console';
import { Modal } from 'react-bootstrap';

interface Step1Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  step: any;
}

const Preview: React.FC<Step1Props> = ({
  handleNextStep,
  handlePreviousStep,
  step,
}) => {
  let dataOnb: any;
  const navigate = useNavigate();

  const { data, mutate, loading } = useGetRequestOnboarding();

  useEffect(() => {
    mutate();
  }, []);
  const [show, setShow] = useState<boolean>(false);

  const onNext = () => {
    navigate('/');
  };

  const submit = () => {
    
  }

  const TableUserWork: React.FC<any> = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Process Type</th>
            <th>Initiator</th>
            <th>Reviewer</th>
            <th>Approver </th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.workflows?.map((userFlow, i) => {
             //console.log(userFlow.roles);

              return (
                <tr key={i}>
                  <td> {i + 1} </td>
                  <td>{userFlow.cat}</td>
                  {userFlow.roles.map((user, i) => {
                    return (
                      <td>
                        {user.users.map((u, i) => {
                          return (
                            <div>
                              <div>{u}</div>
                            </div>
                          );
                        })}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">
                  Congratulations!!
                </span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                 Your registration request is under review. Once approved, your account will be activated, and login credentials will be emailed to the respective users
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <div className="pt-10 mt-9" style={{ background: "#F5F5F5" }}>
          <div className=" m-auto  py-7 responsive-card-large">
            <span className="fs-22s text-dark2 fw-bolder">
              Registration Preview
            </span>
          </div>
          <div className="card m-auto p-11 py-7 responsive-card-large mt-19">
            <div className="d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fs-5 fw-bolder lh-lg">
                  Personal Details
                </span>
              </div>

              {/* <div
                className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e"
                onClick={() => {}}
              >
                <span className="text-white fs-8 fw-bold">Edit</span>
                <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
              </div> */}
            </div>
            <hr className="text-muted h-1px" />
            <div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  First Name
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.admin?.firstName}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Last Name
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.admin?.lastName}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Phone Number
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.admin?.phoneNumber}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Email
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.admin?.email}
                </span>
              </div>

              {/* <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Institution
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.admin?.organizationName}
                </span>
              </div> */}
            </div>
            <hr className="text-muted h-1px" />
          </div>

          <div className="card m-auto p-11 py-7 responsive-card-large mt-10">
            <div className="d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fs-5 fw-bolder lh-lg">
                  Entity Details
                </span>
              </div>

              {/* <div
                className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e"
                onClick={() => {}}
              >
                <span className="text-white fs-8 fw-bold">Edit</span>
                <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
              </div> */}
            </div>
            <hr className="text-muted h-1px" />
            <div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Registered Entity Name
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization?.registeredName}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  MDA Code
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization?.mdaAdminCode}
                </span>
              </div>
              {/* <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Trading Name
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization?.tradingName}
                </span>
              </div> */}
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  MDA Sector
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization.sector?.code}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Entity Physical Address
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization.contact?.physicalAddress}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Entity Country
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization.contact?.country}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Entity State/Province
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {" "}
                  {data?.organization.contact?.state}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Entity City/Region
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {" "}
                  {data?.organization.contact?.city}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Entity Phone Number
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization.contact?.phoneNumber}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Entity Email Address
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization.contact?.email}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Website
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization.contact?.websiteUrl}
                </span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Twitter Handle
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {data?.organization.contact?.twitterHandle}
                </span>
              </div>
              {data?.organization.contact?.facebook && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                    Facebook Handle
                  </span>
                  <span className="text-dark fs-8 fw-bold text-end">
                    {data?.organization.contact?.facebook}
                  </span>
                </div>
              )}
              {data?.organization.contact?.linkedIn && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                    LinkedIn Handle
                  </span>
                  <span className="text-dark fs-8 fw-bold text-end">
                    {data?.organization.contact?.linkedIn}
                  </span>
                </div>
              )}
              {data?.organization.contact?.instagram && (
                <div className="d-flex justify-content-between py-3">
                  <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                    Instagram Handle
                  </span>
                  <span className="text-dark fs-8 fw-bold text-end">
                    {data?.organization.contact?.instagram}
                  </span>
                </div>
              )}

            </div>
            <hr className="text-muted h-1px" />
          </div>

          <div className="card m-auto p-11 py-7 responsive-card-large mt-19">
            <div className="d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fs-5 fw-bolder lh-lg">
                  Bank Account Details
                </span>
              </div>

              {/* <div
                className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e"
                onClick={() => {}}
              >
                <span className="text-white fs-8 fw-bold">Edit</span>
                <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
              </div> */}
            </div>
            <hr className="text-muted h-1px" />
            {data?.bankDetails.map((accountData, i) => {
              return (
                <div key={i}>
                  {/* bank acount */}

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Bank Account Number
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {accountData?.accountNumber}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Bank Account Name
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {accountData?.accountName}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Bank Name
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {accountData?.bankName}
                    </span>
                  </div>

                  <hr className="text-muted h-1px" />
                </div>
              );
            })}
          </div>

          <div className="card m-auto p-11 py-7 responsive-card-large mt-19">
            <div className="d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fs-5 fw-bolder lh-lg">
                  User Details
                </span>
              </div>

          
            </div>
            <hr className="text-muted h-1px" />
            {data?.users?.map((userData, i) => {
              return (
                <div key={i}>
                  {/* bank acount */}

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      First Name
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {userData?.firstName}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Last Name
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {userData?.lastName}
                    </span>
                  </div>


                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Phone Number
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {userData?.phoneNumber}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Email
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {userData?.email}
                    </span>
                  </div>
                  <hr className="text-muted h-1px" />
                </div>
              );
            })}
          </div>

          <div className="card m-auto p-11 py-7 responsive-card-large mt-19">
            <div className="d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fs-5 fw-bolder lh-lg">
                  Approval Workflow Definition
                </span>
              </div>
            </div>
            <hr className="text-muted h-1px" />

            <div className="mb-20">
              <TableUserWork />
            </div>
          </div>

          <nav className={`nav navbar-fixed-signup-bottom mt-9 `} id="nav">
            <div className="nav-center-signup">
              <div className="nav-header-signup">
                <button
                  className="nav-btn-back nav-back-text"
                  id="nav-btn"
                  onClick={handlePreviousStep}
                >
                  <i className="fa fa-solid fa-arrow-left"></i>{" "}
                  <span style={{ marginLeft: "0.2rem" }}>back</span>
                </button>{" "}
                <button
                  id="kt_password_cancel "
                  type="submit"
                  className={`btn nav-btn-Proceed btn-success text-white px-7 py-2`}
                  onClick={() => setShow(true)}
                >
                  {!loading && (
                    <span className="indicator-label">
                      {step === 4 ? "Submit" : " Proceed"}
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </nav>

          {SuccessModal()}
        </div>
      )}
    </>
  );
};

export { Preview };
