import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Moment from "moment";
import { receiptFgnLight,recieptWatermarkbg } from "core/assets/imgs";

const fonts = {
  poppins: {
    regular: "/Poppins/Poppins-Regular.ttf",
    medium: "/Poppins/Poppins-Medium.ttf",
    semiBold: "/Poppins/Poppins-SemiBold.ttf",
    bold: "/Poppins/Poppins-Bold.ttf",
    // Add more font weights if necessary
  },
};

// Register the Poppins font family
Font.register({
  family: "Poppins",
  fonts: [
    { src: fonts.poppins.regular, fontWeight: "normal" },
    { src: fonts.poppins.medium, fontWeight: "medium" },
    { src: fonts.poppins.semiBold, fontWeight: "semibold" },
    { src: fonts.poppins.bold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    //margin: 40,
    backgroundColor: "#fff",
    position: "relative",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: 'red',
  },
  qrCodeWrapper: {},
  mainHeader: {
    flex: 1,
    alignItems: "center",
  },
  mainHeaderText: {
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666",
  },
  headerColumn: {
    flexDirection: "column",
    flexWrap: "wrap",
    // flex: 1,
  },
  smallText: {
    fontSize: 10,
    color: "#666",
    textAlign: "left",
    alignSelf: "flex-end",
    flexWrap: "wrap",
    fontWeight: "normal",
    fontFamily: "Poppins",
  },
  grrValueContainer: {
    marginVertical: 3,
    marginBottom: 10,
    borderWidth: 2,
    borderColor: "#1f8505",
    backgroundColor: "#d2e7cd",
    paddingVertical: 5,
    paddingHorizontal: 10,
    minWidth: 101,
    alignSelf: "flex-end",
  },
  grrValue: {
    color: "#1f8505",
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: "normal",
    textAlign: "right",
    alignSelf: "flex-end",
  },
  date: {
    fontSize: 10,
    color: "#061b01",
    fontWeight: "bold",
    fontFamily: "Poppins",
    flexWrap: "wrap",
    textAlign: "left",
    alignSelf: "flex-end",
  },
  line: {
    marginVertical: 20,
    height: 1,
    backgroundColor: "#ccc",
  },
  marginBottom: {
    marginBottom: 20,
  },
  section: {
    paddingVertical: 2,
    paddingHorizontal: 10,
    // width: 515,
    backgroundColor: "#fef5d7",
    justifyContent: "space-between",
    flexDirection: "row",
    flex: 1,
  },
  sectionHeader: {
    fontSize: 12,
    color: "#061b01",
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
  sectionDetails: {
    flexDirection: "row",
    paddingHorizontal: 10,
    justifyContent: "space-between",
    marginVertical: 4,
    alignItems: "center",
    flex: 1,
  },

  content: {
    color: "#aaa",
    fontSize: 12,
    fontWeight: "normal",
    fontFamily: "Poppins",
    minWidth: 100,
    maxWidth: "40.5%",
  },

  value: {
    fontSize: 12,
    color: "#061b01",
    fontWeight: "medium",
    fontFamily: "Poppins",
    maxWidth: "55%",
    alignSelf: "flex-end",
  },
  serviceAmount: {
    fontSize: 12,
    color: "#061b01",
    fontWeight: "medium",
    fontFamily: "Poppins",
    flex: 0.3,
    alignContent: "center",
    textAlign: "right",
  },

  quantity: {
    color: "#666",
    textAlign: "center",
    fontWeight: "medium",
    fontFamily: "Poppins",
    maxWidth: "10%",
    fontSize: 12,
    flex: 0.1,
  },

  watermark: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    padding: 0,
    paddingBottom: "20%",
    top: 0,
    alignContent: "center",
  },
  watermark2: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    padding: 0,
    top: 0,
    alignContent: "center",
  },

  page: {
    // paddingTop: 35,
    // paddingBottom: 150,
    minHeight:'297mm'
  
  },

  image: {
    height: 80,
    width: 80,
  },
  image2: {
    height: 200,
    width: 199.294,
    opacity: 5
  },

  image3: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
});

const QuickReceipts = (receiptData: any) => {

  const orgName = receiptData?.orgName ?? receiptData?.invoice?.orgName
  const serviceName = receiptData?.serviceName ?? receiptData?.invoice?.serviceName
  const description= receiptData?.description ?? receiptData?.invoice?.description
  const installPayment = receiptData?.installPayment?? receiptData?.invoice?.installPayment

  return (
      <Document>
          <Page size="A4" style={styles.page} wrap={false}>
              <View style={styles.container}>
                  <View style={styles.header}>
                      <View style={styles.qrCodeWrapper}>
                          <Image style={styles.image} src="/receiptQrImg.png" />
                      </View>
                      <View style={styles.mainHeader}>
                          <Text style={styles.mainHeaderText}>Payment Receipt</Text>
                      </View>

                      <View style={styles.headerColumn}>
                          <View>
                              <Text style={styles.smallText}>Government Revenue Reference(GRR)</Text>
                          </View>

                          <View style={styles.grrValueContainer}>
                              <Text style={styles.grrValue}>{receiptData?.grr}</Text>
                          </View>
                          <Text style={styles.smallText}>Generated on</Text>
                          <Text style={styles.date}>
                              {Moment(new Date()).format('MMMM Do YYYY, h:mm a')}
                              {/* {Moment(receiptData.issueDate ? receiptData.issueDate : new Date()).format(
                                  'MMMM Do YYYY, h:mm a',
                              )} */}
                          </Text>
                      </View>
                  </View>
                  <View style={styles.line} />

                  {/* main body start  */}
                  <View>
                      {/*  Payment info start*/}
                      <View style={styles.marginBottom}>
                          <View style={styles.section}>
                              <Text style={styles.sectionHeader}>Payment Information</Text>
                          </View>

                          <View style={styles.sectionDetails}>
                              <Text style={styles.content}>MDA</Text>
                              <Text style={styles.value}>{orgName}</Text>
                          </View>

                          <View style={styles.sectionDetails}>
                              <Text style={styles.content}>Type of Service</Text>
                              <Text style={styles.value}>{serviceName}</Text>
                          </View>

                          <View style={styles.sectionDetails}>
                              <Text style={styles.content}>Description</Text>
                              <Text style={styles.value}>{description?.length > 0 ? description : 'N/A'}</Text>
                          </View>

                          <View style={styles.sectionDetails}>
                              <Text style={styles.content}>Full Name</Text>
                              <Text style={styles.value}>{receiptData?.payer?.fullName}</Text>
                          </View>

                          <View style={styles.sectionDetails}>
                              <Text style={styles.content}>Email</Text>
                              <Text style={styles.value}>{receiptData?.payer?.email}</Text>
                          </View>

                          <View style={styles.sectionDetails}>
                              <Text style={styles.content}>Phone Number</Text>
                              <Text style={styles.value}>{receiptData?.payer?.phone}</Text>
                          </View>

                          <View style={styles.sectionDetails}>
                              <Text style={styles.content}>Transaction Amount</Text>
                              <Text style={styles.value}>
                                  N
                                  {receiptData?.currentPayment?.amountInfo?.amount.toLocaleString() ??
                                      receiptData?.amount?.toLocaleString()}
                              </Text>
                          </View>
                      </View>
                      {/*  Payment info stop*/}

                      {/*  Additional info start*/}
                      {receiptData?.payer?.customValues?.length > 0 && (
                          <View style={styles.marginBottom}>
                              <View style={styles.section}>
                                  <Text style={styles.sectionHeader}>Additional Information</Text>
                              </View>

                              {receiptData?.payer?.customValues?.map((customdata: any, index: any) => {
                                  return (
                                      <View style={styles.sectionDetails} key={index}>
                                          <Text style={styles.content}>{customdata?.field}</Text>
                                          <Text style={styles.value}>{customdata?.value || 'N/A'}</Text>
                                      </View>
                                  );
                              })}
                          </View>
                      )}
                      {/*  Additional info stop*/}

                      {/*   Service items info start*/}
                      {receiptData?.payer?.paymentComponentList?.length >= 1 &&
                          receiptData?.payer?.paymentComponentList[0]?.name !== receiptData?.serviceName && (
                              <View style={styles.marginBottom}>
                                  <View style={styles.section}>
                                      <Text style={{ ...styles.sectionHeader, flex: 0.5 }}>Service</Text>
                                      {/* <Text style={{ ...styles.sectionHeader, flex: 0.5 }}>
                      Quantity
                    </Text> */}
                                      <Text
                                          style={{
                                              ...styles.sectionHeader,
                                              flex: 0.2,
                                              textAlign: 'right',
                                          }}
                                      >
                                          Amount
                                      </Text>
                                  </View>

                                  {receiptData?.payer?.paymentComponentList?.map((payComp: any, index: any) => {
                                      return (
                                          <View style={styles.sectionDetails} key={`${payComp?.name}-${index}`}>
                                              <Text style={{ ...styles.content, flex: 0.5 }}>{payComp?.name}</Text>
                                              {/* <Text style={styles.quantity}>MDA</Text> */}
                                              <Text style={styles.serviceAmount}>
                                                  N{payComp?.amount.toLocaleString()}
                                              </Text>
                                          </View>
                                      );
                                  })}

                                  <View
                                      style={{
                                          ...styles.sectionDetails,
                                          backgroundColor: '#f2f2f2',
                                      }}
                                  >
                                      <Text style={styles.content}>Total</Text>
                                      <Text style={styles.value}>
                                          N
                                          {receiptData?.invoice?.amount.toLocaleString() ??
                                              receiptData?.amount.toLocaleString()}
                                      </Text>
                                  </View>
                              </View>
                          )}
                      {/*   Service items info stop*/}

                      {/*  Summary info start*/}
                      {installPayment && (
                          <View style={styles.marginBottom}>
                              <View style={styles.section}>
                                  <Text style={styles.sectionHeader}>Summary</Text>
                              </View>

                              <View style={styles.sectionDetails}>
                                  <Text style={{ ...styles.content, maxWidth: '45%' }}>Total amount payable</Text>
                                  <Text style={styles.value}>
                                      N
                                      {receiptData?.amount?.toLocaleString() ??
                                          receiptData?.invoice?.amount.toLocaleString()}
                                      {/* ₦ */}
                                  </Text>
                              </View>

                              <View style={styles.sectionDetails}>
                                  <Text style={styles.content}>Amount paid</Text>
                                  <Text style={styles.value}>
                                      N
                                      {(
                                          Number(receiptData?.amount ?? receiptData?.invoice?.amount) -
                                          Number(receiptData?.balanceDue)
                                      ).toLocaleString()}
                                  </Text>
                              </View>

                              <View style={styles.sectionDetails}>
                                  <Text style={styles.content}>Balance due</Text>
                                  <Text style={styles.value}>N{receiptData?.balanceDue.toLocaleString()}</Text>
                              </View>
                          </View>
                      )}
                      {/*  Summary info stop*/}
                  </View>
              </View>

              <View style={styles.watermark} fixed>
                  <Image src={receiptFgnLight} style={styles.image2} />
              </View>

              <View style={styles.watermark2} fixed>
                  <Image src="/recieptWatermarkbg.png" style={styles.image3} />
              </View>
          </Page>
      </Document>
  );
};
export default QuickReceipts;
