import { useLocation } from "react-router-dom";

export function checkIsHome(url: string, hash: string) {
  const home = "/";
  const about = "#about";
  const benefits = "#benefits";
  const help = "#help";
  const mdahome = "/mda-home";
  const mdahome2 = "/mda-home/";

  if (
    url === home ||
    url === mdahome ||
    (hash === help && url === home) ||
    (hash === about && url === home) ||
    (hash === benefits && url === mdahome2) ||
    (hash === about && url === mdahome2) ||
    (hash === help && url === mdahome2)
  ) {
    return true;
  }
  return false;
}

export function checkIsMdaHome(url: string, hash: string) {
  const benefits = "#benefits";
  const about = "#about";
  const help = "#help";
  const mdahome = "/mda-home";
  const mdahome2 = "/mda-home/";

  if (
    url === mdahome ||
    (hash === benefits && url === mdahome2) ||
    (hash === about && url === mdahome2) ||
    (hash === help && url ===mdahome2)
  ) {
    return true;
  }

  return false;
}

export function WhichHome() {
  const { pathname } = useLocation();
  const mdahome = "/mda-home";
  const userHome = "/";
  const helpCenter = "/help-center";
  const benefits = "#benefits";
  const about = "#about";
  const help = "#help";
  const mdahome2 = "/mda-home/";

  if (
    mdahome === pathname ||
    helpCenter === pathname ||
    (window.location.hash === benefits && pathname === mdahome2) ||
    (window.location.hash === about && pathname === mdahome2) ||
    (window.location.hash === help && pathname === mdahome2)
  ) {
    return "mdaHome";
  }

  if (userHome === pathname) {
    return "userHome";
  }
  return "userHome";
}
