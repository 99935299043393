import React, { useEffect, useState } from 'react';

import { Layout } from '../layout';
import { Aside } from '../components/Aside';
import { arrowDown, arrowRight, arrowUp } from 'core/assets/imgs';
import { Col, Container, Row } from 'react-bootstrap';
import { useMdaDetails } from '../../auth/hooks/useUser';
import { FetchAndUnmask } from 'core/helpers';

const CorporateProfile: React.FC = () => {
  const [state, setState] = useState(1);

  const stateToggle = () => {};

  const corporate = {
    regName: "Ministry of Education",
    tradingName: "Ministry of Education",
    adminCode: "01-01-0003",
    sector: "Education",
    parentOrganiastion: "Federal Ministry of Education",
    country: "Nigeria",
    state: "Lagos",
    city: "Ikeja",
    phoneNo: "2348060416760",
    email: "ministryofeducation@gmail.com",
  };


    const {
    data: detailsData,
    mutate: detailsMutate,
    isLoading,
    } = useMdaDetails();
  
  useEffect(() => {

 const orgId: string | null = FetchAndUnmask("orgId");


    detailsMutate(orgId)

  }, [])

 //console.log(detailsData);
  

  const Corporate = () => {
    return (
      <div className="main-step setup-body-padding-bottom">
        <div className="corporate-container-form">
          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">Registered Name</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.registeredName}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>

          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">Country</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.contact?.country}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>
        </div>

        <div className="corporate-container-form">
          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">Administrative Code</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.mdaCode}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>

          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">Sector</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.sector?.code}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>
        </div>

        {/* <div className="corporate-container-form">
          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">Parent Organizatione</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.mdaCode}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>

      
        </div> */}

        <div className="corporate-container-form">
          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">State</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.contact?.state}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>

          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">City</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.contact?.city}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>
        </div>

        <div className="corporate-container-form">
          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">Phone Number</label>
            </div>

            <div className="form-control form-control-solid p-2 ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.contact?.phoneNumber}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>

          <div className="mt-9">
            <div className="col-lg-6 col-form-label py-0 input-height">
              <label className="fw-bold fs-9 text-muted">Email Address</label>
            </div>

            <div className="form-control form-control-solid p-2  ">
              <input
                type="text"
                placeholder=""
                value={detailsData?.organization?.contact?.email}
                className={"no-outline text-muted fs-8"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Accounts = () => {
    return (
      <div className="setup-body-padding-bottom">
        <div className="main-step-account">
          <div className="p-9">
            {/* <button className="btn-dash-main ">
              <span>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-5"
                >
                  <path
                    d="M4.7915 10.2917H14.7915M9.7915 5.29175V15.2917"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="btn-text-dash-main ms-5">
                  Add more Accounts
                </span>
              </span>
            </button> */}
          </div>
        </div>

        <div className="dashboard-collection-div">
          <div>
            <span className="dashboard-collection-text">
               Accounts
            </span>
          </div>
          <Container className="gx-0">
            <Row className="gy-5 mt-2">

              {detailsData?.organization?.bankDetails?.map((bankD, i) => {


                return (
                     <Col xs={12} md={6} lg={4} className="" key={i}>
                <div className="collection-root-box">
                  <div className="collection-root-box-body-root">
                    <div className="collection-root-icon">
                      <svg
                        width="35"
                        height="36"
                        viewBox="0 0 35 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.375 19.0938L17.5 32.2188L30.625 19.0938H24.0625V7.0625C24.0625 6.77242 23.9473 6.49422 23.7421 6.2891C23.537 6.08398 23.2588 5.96875 22.9688 5.96875H12.0312C11.7412 5.96875 11.463 6.08398 11.2579 6.2891C11.0527 6.49422 10.9375 6.77242 10.9375 7.0625V19.0938H4.375Z"
                          stroke="#1F8505"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="collection-data-container">
                      <span className="collection-data-container-item1 mt-1">
                        Account No
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {bankD?.accountNumber}
                      </span>
                      <span className="collection-data-container-item1 mt-1">
                        Account Name
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {' '}
                       {bankD?.accountName}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
                )
              })}
           
            
            </Row>
          </Container>
        </div>

        {/* <div className="dashboard-collection-div">
          <div>
            <span className="dashboard-collection-text-funding">
              Funding Accounts
            </span>
          </div>
          <Container className="gx-0">
            <Row className="gy-5 mt-2">
              <Col xs={12} md={6} lg={4} className="">
                <div className="collection-root-box">
                  <div className="collection-root-box-body-root">
                    <div className="collection-root-icon">
                      <svg
                        width="35"
                        height="36"
                        viewBox="0 0 35 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.375 16.9062L17.5 3.78125L30.625 16.9062H24.0625V28.9375C24.0625 29.2276 23.9473 29.5058 23.7421 29.7109C23.537 29.916 23.2588 30.0312 22.9688 30.0312H12.0312C11.7412 30.0312 11.463 29.916 11.2579 29.7109C11.0527 29.5058 10.9375 29.2276 10.9375 28.9375V16.9062H4.375Z"
                          stroke="#EE151D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="collection-data-container">
                      <span className="collection-data-container-item1 mt-1">
                        Account No
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        0069185929
                      </span>
                      <span className="collection-data-container-item1 mt-1">
                        Account Name
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {' '}
                        Ministry of Education{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="">
                <div className="collection-root-box">
                  <div className="collection-root-box-body-root">
                    <div className="collection-root-icon">
                      <svg
                        width="35"
                        height="36"
                        viewBox="0 0 35 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.375 16.9062L17.5 3.78125L30.625 16.9062H24.0625V28.9375C24.0625 29.2276 23.9473 29.5058 23.7421 29.7109C23.537 29.916 23.2588 30.0312 22.9688 30.0312H12.0312C11.7412 30.0312 11.463 29.916 11.2579 29.7109C11.0527 29.5058 10.9375 29.2276 10.9375 28.9375V16.9062H4.375Z"
                          stroke="#EE151D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="collection-data-container">
                      <span className="collection-data-container-item1 mt-1">
                        Account No
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        0069185929
                      </span>
                      <span className="collection-data-container-item1 mt-1">
                        Account Name
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {' '}
                        Ministry of Education{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="">
                <div className="collection-root-box">
                  <div className="collection-root-box-body-root">
                    <div className="collection-root-icon">
                      <svg
                        width="35"
                        height="36"
                        viewBox="0 0 35 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.375 16.9062L17.5 3.78125L30.625 16.9062H24.0625V28.9375C24.0625 29.2276 23.9473 29.5058 23.7421 29.7109C23.537 29.916 23.2588 30.0312 22.9688 30.0312H12.0312C11.7412 30.0312 11.463 29.916 11.2579 29.7109C11.0527 29.5058 10.9375 29.2276 10.9375 28.9375V16.9062H4.375Z"
                          stroke="#EE151D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="collection-data-container">
                      <span className="collection-data-container-item1 mt-1">
                        Account No
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        0069185929
                      </span>
                      <span className="collection-data-container-item1 mt-1">
                        Account Name
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {' '}
                        Ministry of Education{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}

        {/* <div className="dashboard-collection-div">
          <div>
            <span className="dashboard-collection-text-review">
              {' '}
              Accounts Under Review
            </span>
          </div>

          <Container className="gx-0">
            <Row className=" gy-5 mt-2">
              <Col xs={12} md={6} lg={4} className="">
                <div className="collection-root-box">
                  <div className="collection-root-box-body-root">
                    <div className="collection-data-container">
                      <span className="collection-data-container-item1 mt-1">
                        Account No
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        0069185929
                      </span>
                      <span className="collection-data-container-item1 mt-1">
                        Account Name
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {' '}
                        Ministry of Education{' '}
                      </span>
                    </div>
                    <div>
                      <span className="collection-data-container-inactive">
                        Inactive
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="">
                <div className="collection-root-box">
                  <div className="collection-root-box-body-root">
                    <div className="collection-data-container">
                      <span className="collection-data-container-item1 mt-1">
                        Account No
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        0069185929
                      </span>
                      <span className="collection-data-container-item1 mt-1">
                        Account Name
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {' '}
                        Ministry of Education{' '}
                      </span>
                    </div>
                    <div>
                      <span className="collection-data-container-inactive">
                        Inactive
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="">
                <div className="collection-root-box">
                  <div className="collection-root-box-body-root">
                    <div className="collection-data-container">
                      <span className="collection-data-container-item1 mt-1">
                        Account No
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        0069185929
                      </span>
                      <span className="collection-data-container-item1 mt-1">
                        Account Name
                      </span>
                      <span className="collection-data-container-item2 mt-1">
                        {' '}
                        Ministry of Education{' '}
                      </span>
                    </div>
                    <div>
                      <span className="collection-data-container-inactive">
                        Inactive
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
      </div>
    );
  };

  const faq = [
    {
      name: 'User Administration',
      initiator: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
      reviewer: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],

      approver: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
    },
    {
      name: 'Collection Rules',
      initiator: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
      reviewer: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],

      approver: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
    },
    {
      name: 'Payroll Payments',
      initiator: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
      reviewer: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],

      approver: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
    },
    {
      name: 'Vendor/Contract Payments',
      initiator: [
        'Tobechwukwu Ekwealor Chibuzor Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
      reviewer: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],

      approver: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
    },
    {
      name: 'Tax Payments',
      initiator: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
      reviewer: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],

      approver: [
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
        'Tobechwukwu Ekwealor Chibuzor',
      ],
    },
  ];

  const users = [
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 1 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 2 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 3 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 4 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 5 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 6 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 7 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 8 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 9 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 10 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 11 },
    { name: 'Tobechwukwu Ekwealor Chibuzor', id: 12 },
  ];

  const [arrow, setArrow] = useState(true);
  const [FaqNo, setFaqNo] = useState<Number>(5);
  const handleFaq = (index: Number) => {
    if (index == FaqNo && arrow) {
      setArrow(false);
    } else {
      setArrow(true);
      setFaqNo(index);
    }
  };
  const Team = () => {
    return (
      <div className="main-step setup-body-padding-bottom">
        <div>
          <span className="tean-header-text">All Users ({detailsData?.users?.length})</span>
        </div>

        <div className="dashboard-step-team-root mt-5 fs-9 fw-bold user-grid  ">
          {detailsData?.users?.map((data, index) => {
            return (
              <li className="stop-long-text" key={index}>
                <span>{data.firstName} - {data.lastName}</span>
              </li>
            );
          })}
        </div>

        <div className="mt-10">
          <span className="tean-header-text">
            Users with their Roles Categorised by Process Types
          </span>

          <div className="my-5">
            {detailsData?.workflows?.map((value, index) => {
              return (
                <div key={index}>
                  <div
                    className={`border-bottom border-gray-800 faq-header w-100 ${
                      arrow && FaqNo === index ? '' : 'bg-white'
                    }  `}
                    onClick={() => handleFaq(index)}
                  >
                    <span className="faq-header-text ">{value?.cat}</span>
                    {arrow && FaqNo === index ? (
                      <span>
                        <img src={arrowUp} />
                      </span>
                    ) : (
                      <span>
                        <img src={arrowDown} />
                      </span>
                    )}
                  </div>

                  {arrow && FaqNo === index && (
                    <div className="faq-body mobile-flex  w-100">
                      <div className="equal-width">
                        <div className="text-center mb-2">
                          <span className=" upperLetter underline-dark fs-8 fw-bolder">
                            Initator
                          </span>
                        </div>
                        <ol className="stop-long-text">
                          {value?.roles[0].users?.map((name, key) => (
                            <li key={key} className="">
                              {name}
                            </li>
                          ))}
                        </ol>
                      </div>

                      <div className="equal-width">
                        <div className="text-center mb-2">
                          <span className=" upperLetter underline-dark fs-8 fw-bolder">
                            Reviewer
                          </span>
                        </div>
                        <ol className="stop-long-text">
                         {value?.roles?.[1]?.users && value?.roles?.[1].users?.map((name, key) => (
                            <li key={key} className="">
                              {name}
                            </li>
                          ))}
                        </ol>
                      </div>

                      <div className="equal-width">
                        <div className="text-center mb-2">
                          <span className=" upperLetter underline-dark fs-8 fw-bolder">
                            Approver
                          </span>
                        </div>
                        <ol className="stop-long-text">
                         {value?.roles?.[2]?.users && value?.roles?.[2].users?.map((name, key) => (
                            <li key={key} className="">
                              {name}
                            </li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="dashboard-root">
        <div className="dashboard-main-header">
          <div className="setup-administration">Setup & Administration</div>
          <div>
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>
          </div>
          <div className="corporate-profile">Corporate Profile</div>
        </div>
        <div className="dashboard-header-img"></div>
        <main>
          <div className="main-inner">
            <Aside data={detailsData?.organization}></Aside>
            <div className="dashboard-main">
              <div className="dashboard-main-headers">
                <div
                  className={
                    state === 1
                      ? 'dasboard-header-text-active'
                      : 'dasboard-header-text'
                  }
                  onClick={() => setState(1)}
                >
                  Corporate Information
                </div>
                <div
                  className={
                    state === 2
                      ? 'dasboard-header-text-active'
                      : 'dasboard-header-text'
                  }
                  onClick={() => setState(2)}
                >
                  Accounts
                </div>
                <div
                  className={
                    state === 3
                      ? 'dasboard-header-text-active'
                      : 'dasboard-header-text'
                  }
                  onClick={() => setState(3)}
                >
                  Team
                </div>
              </div>
              <div>
                {state === 1 && Corporate()}
                {state === 2 && Accounts()}
                {state === 3 && Team()}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export { CorporateProfile };
